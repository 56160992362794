<template>
    <div :class="[$route.name=='oa' ? 'word-cloud-component1' : 'word-cloud-component2']" id="wordCloud">

    </div>
</template>

<script>
export default {
    props: ['chartData2'],
    data() {
        return {
            chartList2: []
        }
    },
    watch: {
        chartData2 (newVal, oldVal) {
            this.chartList2 = newVal
            this.init()
        }
    },
    mounted () {
        this.init()
    },
    methods: {
        init () {
            var x = []
            this.chartList2.forEach((e, ind) => {
                x.push({
                    name: e.name,
                    value: e.count
                })
            })
            var keywords = x
            // [{"name":"男神","value":2.64},
            //                 {"name":"二个词","value":4.03},
            //                 {"name":"一个词","value":24.95},
            //                 {"name":"酷","value":4.04},
            //                 {"name":"时尚","value":5.27},
            //                 {"name":"阳光活力","value":5.80},
            //                 {"name":"初恋","value":3.09},
            //                 {"name":"英俊潇洒","value":24.71},
            //                 {"name":"霸气","value":6.33},
            //                 {"name":"腼腆","value":2.55},
            //                 {"name":"蠢萌","value":3.88},
            //                 {"name":"青春","value":8.04},
            //                 {"name":"网红","value":5.87},
            //                 {"name":"萌","value":6.97},
            //                 {"name":"认真","value":2.53},
            //                 {"name":"古典","value":2.49},
            //                 {"name":"温柔","value":3.91},
            //                 {"name":"有个性","value":3.25},
            //                 {"name":"可爱","value":9.93},
            //                 {"name":"幽默诙谐","value":3.65}]
            var myChart = this.$echarts.init(document.getElementById('wordCloud'))
            var option = {
                // color: ['#C30E24', '#000', '#0e0e0e', '#363636', '#797979', '#B0B0B0'],
                    backgroundColor:'#fff',
                    tooltip: {
                        show: true
                    },
                    series: [{
                        type: 'wordCloud',
                        gridSize: 14,//词云中每个词的间距。
                        sizeRange: [13, 55],
                        // rotationRange: [-45, 90],
                        rotationRange: [0, 0],
                        // rotationRange: [-45, 0, 45, 90],
                        // maskImage: mask,
                        textStyle: {
                                color: function(e) {
                                    var colors =  ['#C30E24','#C30E24', '#000000', '#000000', '#0e0e0e', '#0e0e0e', '#363636', '#363636', '#797979', '#B0B0B0']
                                    // return colors[parseInt(Math.random() * 10)]
                                    if (e.dataIndex<10) {
                                        return colors[e.dataIndex]
                                    } else if (e.dataIndex>9 && e.dataIndex<20) {
                                        return colors[e.dataIndex-10]
                                    } else if (e.dataIndex>19 && e.dataIndex<30) {
                                        return colors[e.dataIndex-20]
                                    } else if (e.dataIndex>29 && e.dataIndex<40) {
                                        return colors[e.dataIndex-30]
                                    } else if (e.dataIndex>39 && e.dataIndex<50) {
                                        return colors[e.dataIndex-40]
                                    }
                                    // if (e.dataIndex<10) {
                                    //     return colors[0]
                                    // } else if (e.dataIndex>9 && e.dataIndex<20) {
                                    //     return colors[2]
                                    // } else if (e.dataIndex>19 && e.dataIndex<30) {
                                    //     return colors[4]
                                    // } else if (e.dataIndex>29 && e.dataIndex<40) {
                                    //     return colors[6]
                                    // } else if (e.dataIndex>39 && e.dataIndex<50) {
                                    //     return colors[8]
                                    // }
                                    
                                
                            },
                            emphasis: {
                                shadowBlur: 10,
                                shadowColor: '#333'
                            }
                        },
                        left: 'center',
                        top: 'center',
                        width: '96%',
                        height: '100%',
                        right: null,
                        bottom: null,
                        // shape: 'diamond',
                        //词云的形状，默认是circle(圆形)，可选的参数有cardioid（心形）、diamond（菱形 正方形）、triangle-forward、triangle（三角形）、star（星形）、pentagon （五边形）；
                        
                        

                        // width: 300,
                        // height: 200,
                        // top: 20,
                        data: keywords
                    }]
                }
                myChart.setOption(option, true)
        }
        
    },
}
</script>

<style lang="scss" scoped>
.word-cloud-component1 {
    width: 590px;
    height: 350px;
}
.word-cloud-component2 {
    width: 300px;
    height: 220px;
}
</style>