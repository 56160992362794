<template>
    <div class="preprint-page">
        <HeaderAnnualMeeting />
        <div style="height: 400px;">
            <HeaderPreprint />
        </div>

        <!-- <div class="wid1200">
            <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
        </div> -->
        <section class="preprint-main">

            <div class="top-banner-wrap">
                <div class="wid1200">
                    <div class="top-banner-component">

                        <div class="left-top-banner">
                            <h5>{{bannerData.title}}</h5>
                            <h6>BRIEF INTRODUCTION</h6>
                            <div>
                                <p class="banner-content" v-html="bannerData.content">
                                </p>
                                <span @click="goIntro">更多 >></span></span>
                            </div>

                        </div>
                        <div class="right-top-banner">
                            <ComBannerPreprint :bannerData="imgBannerData" :pageName="pageName" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="wid1200 main-top">
                <div class="latest-release">
                    <div class="title-preprint">
                        <h5>最新发布</h5>
                        <a @click="goArticle">更多<img src="../assets/img/oa/icon-more.png" alt="" /></a>
                    </div>
                    <ul class="title-btn">
                        <li :class="[articleSubjectId==item.id? 'active-title': ''] " v-for="(item, ind) in subjectData"
                            :key="ind" v-if="ind<5" @click="changeArticleSubject(item.id)">{{item.name}}</li>
                        <!-- <li>社会学</li>
                        <li>社会工作</li>
                        <li>公共管理</li>
                        <li>社会政策与社会保障</li> -->
                    </ul>
                    <ul class="article-list">
                        <li v-for="(item, index) in articleData.articleList" :key="index" v-if="index<5">
                            <a href="#"><img src="../assets/img/oa/icon-ssap.png" alt="" /></a>
                            <div class="article-content">
                                <h6 @click="goDetail(item)">
                                    {{item.searchTitle ? item.searchTitle : item.title}}<span>{{item.subject}}</span>
                                </h6>
                                <div>
                                    <p class="author" v-if="item.author">作者：{{item.author}}</p>
                                    <!-- 所属集刊 -->
                                    <!-- <p class="name" v-if="item.source">{{item.source}}</p> -->
                                    <p class="time" v-if="item.releaseDate">日期：{{item.releaseDate}}</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="contribute-news-wrap">
                    <button><a href="https://www.iedol.cn/#/main/newPreprint?roleld=7" target="_blank">我要投稿</a></button>
                    <div class="news-preprint">
                        <div class="title-preprint">
                            <h5>预印本资讯</h5>
                            <a @click="goNews('yyb_zx')">更多<img src="../assets/img/oa/icon-more.png" alt="" /></a>
                        </div>
                        <!-- <p><a @click="goNewsDetail(item)">{{item.title}}</a></p><span>{{item.releaseDate}}</span> -->
                        <ul>
                            <li v-for="(item, index) in newsData" :key="index">
                                <a @click="goNewsDetail(item)">{{item.title}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="wid1200 bot-main">
                <div class="cooperation-left">
                    <div class="title-preprint">
                        <h5>
                            <p :class="['first-title-plat', isFirstCoo==1? 'active-title-plat' : '']"
                                @click="switchCoo(1)">预印本合作集刊</p>
                            <p :class="['sec-title-plat', isFirstCoo==2? 'active-title-plat' : '']"
                                @click="switchCoo(2)">预印本合作机构</p>
                            <!-- active-title-plat -->
                        </h5>
                        <!-- <a href="#">更多<img src="../assets/img/oa/icon-more.png" alt="" /></a> -->
                        <div class="pagination-wrap">
                            <span class="total-page">共 {{ isFirstCoo==1 ? totalPageBook : totalPageIns }} 页</span>
                            <el-pagination @current-change="partnerChange" :page-size="pageSize"
                                :current-page="currentPage" layout="prev, next" :total="total">
                                <!--total , pager,  -->
                            </el-pagination>
                        </div>
                    </div>
                    <div class="coo-book" v-if="isFirstCoo==1">
                        <ul class="com-book">
                            <li v-for="(item, index) in bookData.bookList" :key="index" v-if="index<5"
                                @click="goCollect(item)">
                                <img v-if="item.titleImg" :src="item.titleImg" alt="" />
                                <img v-else-if="item.img" :src="item.img" alt="">
                                <img v-else alt="">
                                <!-- <p v-if="item.isInner==0">
                                    {{item.name}}
                                </p> -->

                                <p :title="item.title">{{item.title}}</p>
                            </li>

                        </ul>
                        <ul class="com-book" v-if="bookData.bookList.length>5">
                            <li v-for="(item, index) in bookData.bookList" :key="index" v-if="index>4"
                                @click="goCollect(item)">
                                <img v-if="item.titleImg" :src="item.titleImg" alt="" />
                                <img v-else-if="item.img" :src="item.img" alt="">
                                <img v-else alt="">
                                <!-- <p v-if="item.isInner==0">
                                    {{item.name}}
                                </p> -->

                                <p :title="item.title">{{item.title}}</p>

                            </li>
                            <!--<li>
                                <img src="../assets/img/book.png" alt="" />
                                <p>比较政治学研究</p>
                            </li> -->

                        </ul>
                    </div>

                    <ul class="coo-institution" v-if="isFirstCoo==2">
                        <li v-for="(item, ind) in cooInstitutionData" :key="ind">
                            <router-link :to="{path:'/ins/i', query: {id: item.id, pN: pageName}}">
                                <!-- <img src="../assets/img/oa/test.png" alt="" /> -->
                                <img :src="item.picture" alt="" />
                                <p>{{item.name}}</p>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="more-right">
                    <div class="dynamics-right">
                        <div class="title-preprint">
                            <h5>预印本引用动态</h5>
                            <a @click="goNews('yyb_wzdt')">更多<img src="../assets/img/oa/icon-more.png" alt="" /></a>
                        </div>
                        <ul>
                            <li v-for="(item, index) in articleDynamicData" :key="index">
                                <a @click="goNewsDetail(item)">{{item.title}}</a>
                                <!-- <p><a @click="goNewsDetail(item)">{{item.title}}</a></p><span>{{item.releaseDate}}</span> -->
                            </li>
                        </ul>
                    </div>
                    <div class="key-chart">
                        <div class="title-preprint">
                            <h5>预印本关键词</h5>
                            <!-- <a href="#">更多<img src="../assets/img/oa/icon-more.png" alt="" /></a> -->
                        </div>
                        <div class="chart">
                            <!-- wordCloudData -->
                            <word-cloud :chartData2="wordCloudData" />
                        </div>
                    </div>

                </div>
            </div>

            <div class="wid1200 platform-dynamics">
                <div class="platform-left">
                    <div class="title-preprint">
                        <h5>
                            <p :class="['first-title-plat', isFirstPlatform==3? 'active-title-plat' : '']"
                                @click="switchPlatform(3)">国外预印本平台</p>
                            <p :class="['sec-title-plat', isFirstPlatform==2? 'active-title-plat' : '']"
                                @click="switchPlatform(2)">国内预印本平台</p>
                            <!-- active-title-plat -->
                        </h5>
                        <router-link :to="{path: '/platform/p', query: {type: isFirstPlatform, pN: pageName}}">更多<img
                                src="../assets/img/oa/icon-more.png" alt="" /></router-link>
                        <!-- <a href="#">更多<img src="../assets/img/oa/icon-more.png" alt="" /></a> -->
                    </div>
                    <ul class="abroad" v-if="isFirstPlatform==3">
                        <li v-for="(item, ind) in outPlatformData" :key="ind">
                            <a :href="item.domain" target="_blank">
                                <!-- <img src="../assets/img/oa/test1.png" alt="" /> -->
                                <img :src="item.logo" alt="" />
                                <h6>{{item.siteName}}</h6>
                                <p><span>{{item.info}}</span></p>
                            </a>
                        </li>
                        <!-- <li><a href="#">
                            <img src="../assets/img/oa/test1.png" alt="" />
                            <h6>ChemRxiv</h6>
                            <p><span>arXiv是一个收集物理学、数学、计算机科学与生物学等学科的论文预印本的计算机科学与生物学等学科的论文预印本的…</span></p>
                        </a></li>
                        -->
                    </ul>
                    <ul class="domestic" v-if="isFirstPlatform==2">
                        <li v-for="(item, ind) in inPlatformData" :key="ind">
                            <a :href="item.domain" target="_blank">2016</a>
                            <div class="platform-content">
                                <h6 @click="goInPlat(item.domain)">{{item.siteName}}</h6>
                                <div>
                                    <p>平台简介：{{item.info}}</p>
                                </div>
                            </div>
                        </li>
                        <!-- <li>
                            <a href="#">2016</a>
                            <div class="platform-content">
                                <h6>ChinaXiv中国科学院科技论文预发布平台</h6>
                                <div>
                                    <p>平台简介：ChinaXiv是一个面向科研人员的、可靠的、规范的自然科学领域的中国科……</p>
                                </div>
                            </div>
                        </li>-->
                    </ul>
                </div>

                <div class="btn-wrap">
                    <button class="up-btn" v-if="isUp" @click="handleSub" style="cursor: pointer;">
                        <img src="../assets/img/oa/up1.png" alt="" style="cursor: pointer;" />
                    </button>
                    <button v-else class="up-btn" style="cursor: not-allowed;">
                        <img src="../assets/img/oa/up2.png" alt="" style="cursor: not-allowed;" />
                    </button>
                    <div class="button-wrap">
                        <ul class="button-ul">
                            <li v-for="item in instroData" :key="item.id" @click="goInstro(item)">
                                <a :title="item.title">
                                    {{item.title}}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <button class="down-btn" v-if="isDown" @click="handleAdd">
                        <img src="../assets/img/oa/down1.png" alt="" style="cursor: pointer;" />
                    </button>
                    <button class="down-btn" v-else style="cursor: not-allowed;">
                        <img src="../assets/img/oa/down2.png" alt="" style="cursor: not-allowed;" />
                    </button>
                </div>

            </div>
        </section>
        <!-- <div class="footer-oa-wrap wid1200">
            <FooterOa></FooterOa>
        </div> -->

    </div>
</template>

<script>
import HeaderAnnualMeeting from '../components/HeaderAnnualMeeting'
import BreadCrumb from '../components/BreadCrumb'
// import TopBanner from '../components/ComBanner'
// import TopBanner from '../components/TopBanner'
// import ComArticle from '../components/ComArticle'
// import ComBook from '../components/ComBook'
import HeaderPreprint from '../components/HeaderPreprint'
import ComBannerPreprint from '../components/ComBannerPreprint'
import WordCloud from '../components/ChartWordCloud2'
import FooterOa from '../components/FooterOa'

import img1 from '../assets/img/book.png'

export default {
    name: '',
    components: { BreadCrumb, HeaderPreprint, HeaderAnnualMeeting, ComBannerPreprint, WordCloud, FooterOa },
    data() {
        return {
            pN: '',
            pageName: '哲学社会科学成果预印本平台',
            newsData: [],
            articleDynamicData: [],
            bannerData: {
                title: '预印本简介',
                content: '网课是疫情期间大学课程教学的主要形式。调查表明，直播、复合 型网课是主流。大部分大学生对网课适应、基本满意，但在学习效果判断上态度犹豫。大学生普遍反映，网课在“网络速度”“师生互动”等方面需要改进。网络授课也将对大学学习的形式产生深刻影响，但它毕竟难以取代真正的大学学习生活。尽管疫情的发展还有一定不确定性，从调查结果来看，约... ',
            },
            imgBannerData: [],
            articleData: {
                title: '预印本文章',
                articleList: [],
            },
            bookData: {
                title: '预印本合作方',
                bookList: [],
            },
            articleSubjectId: '',
            bookSubjectId: '',
            isFirstPlatform: 3, //  类型  2:国内预印本平台 3:国外预印本平台
            inPlatformData: [],
            outPlatformData: [],
            isFirstCoo: 1,
            isUp: false,
            isDown: false,
            subjectData: [],
            currentPage: 1,
            cooInstitutionData: [],
            total: 0,
            pageSize: 10,
            totalPageBook: 0,
            totalPageIns: 0,
            wordCloudData: [],
            localUrl: '',
            instroData: [],
            activeTitle: '',
        }
    },
    mounted() {
        this.localUrl = window.location.host.slice(5)
    },
    methods: {
        // handleSub() {
        //     let imgBox = document.getElementsByClassName('button-ul')[0]
        //     if (imgBox.offsetTop > 0 || imgBox.offsetTop == 0) {
        //         this.isUp = false
        //     } else {
        //         imgBox.style.marginTop = imgBox.offsetTop + 66 + 'px'
        //         this.isDown = true
        //         setTimeout(() => {
        //             if (imgBox.offsetTop > 0 || imgBox.offsetTop == 0) {
        //                 this.isUp = false
        //             }
        //         }, 1000)
        //     }
        // },
        // handleAdd() {
        //     let imgBox = document.getElementsByClassName('button-ul')[0]
        //     // console.log(55555,imgBox.clientHeight, imgBox.offsetTop)

        //     // console.log(66666, imgBox.offsetTop)
        //     var x = -(imgBox.clientHeight - 400)
        //     if (x > imgBox.offsetTop) {
        //         this.isDown = false
        //     } else {
        //         imgBox.style.marginTop = imgBox.offsetTop + -66 + 'px'
        //         this.isUp = true
        //         setTimeout(() => {
        //             if (x > imgBox.offsetTop) {
        //                 this.isDown = false
        //             }
        //         }, 1000)
        //     }
        // },
        handleSub () {
            let imgBox = document.getElementsByClassName('button-ul')[0]
            if (imgBox.offsetTop>0 || imgBox.offsetTop==0) {
                this.isUp = false
            } else {
                console.log('imgBox.offsetTop', imgBox.offsetTop)
                if ((imgBox.offsetTop+66)>0 || (imgBox.offsetTop+66)==0) {
                        this.isUp = false
                    }
                this.isDown = true
                imgBox.style.marginTop=imgBox.offsetTop+66+'px'
            }

        },
        handleAdd () {

            // if(!el.disabled){
			//     el.disabled = true
			//     // el.style.backgroundColor = '#ccc'
			//     // el.style.border = 'none'
       		// 	setTimeout(() => {
          	// 		el.disabled = false
          	// 		//  el.style.backgroundColor = '#1890ff'
          	// 		//  el.style.border = '1px solid #1890ff'
        	// 	}, 1000)
			// }

            let imgBox = document.getElementsByClassName('button-ul')[0]
            // console.log(55555,imgBox.clientHeight, imgBox.offsetTop)

            // console.log(66666, imgBox.offsetTop)
            var x = -(imgBox.clientHeight-320)//334)
            //400)
            // console.log(imgBox.clientHeight, 'ffffff', x,'xxxxxx', imgBox.offsetTop)
            // x+66
            if (x>imgBox.offsetTop || x==imgBox.offsetTop) {
                this.isDown = false
            } else {
                // console.log('imgBox.offsetTop', imgBox.offsetTop)
                if ((x+66)>imgBox.offsetTop || (x+66)==imgBox.offsetTop) {
                    this.isDown = false
                }
                this.isUp = true
                imgBox.style.marginTop=imgBox.offsetTop+(-66)+'px'

                // console.log('imgBox.offsetTop', imgBox.offsetTop)
            }
        },
        switchCoo(ind) {
            this.isFirstCoo = ind
            this.currentPage = 1
            if (ind == 1) {
                //预印本合作集刊
                this.pageSize = 10
                this.getCooBook(1)
            } else {
                //预印本合作机构
                this.pageSize = 15
                this.getCooInstitution(1)
            }
        },
        switchPlatform(ind) {
            this.isFirstPlatform = ind
            this.getPlatformData()
        },
        // changeSubject() {
        //     // console.log(this.subject)
        //     this.currentPage = 1
        //     this.$emit('changeSubject', this.subject)
        // },
        goInstro(item) {
            this.$router.push({ path: '/oaIntro/o', query: { titleId: item.id, id: 'yyb_gyyyb', pN: encodeURI(this.pageName) } })
        },
        goInPlat(url) {
            window.open(url)
        },
        goArticle() {
            // oaType=OA图书
            // http://jikan.b.com/oaS/o?searchKey=%20&oaType=OA%E5%9B%BE%E4%B9%A6
            this.$router.push({ path: '/pS/p', query: { source: 'preprint', pN: encodeURI(this.pageName) } })
        },
        goDetail(item) {
            // console.log('item', item)
            // debugger
            this.$router.push({ path: '/pD/p', query: { id: item.id, pN: encodeURI(this.pageName) } })
        },
        goCollect(item) {
          this.$router.push({ path: '/c/c', query: { id: item.innerResourceId, pN: encodeURI(this.pageName) } })
        },
        changeArticleSubject(val) {
            this.articleSubjectId = val
            this.getArticleData(1)
        },
        changeBookSubject(val) {
            //学科
            this.bookSubjectId = val
            this.getCooBook(1)
            //             /api/aloneArticle/articleClassify
            // 预印本文章列表：   /api/aloneArticle/articleList subjectId (取分类的id)
        },

        goIntro() {
            this.$router.push({ path: '/preprintIntro/p', query: { pN: encodeURI(this.pageName), id: 'yyb_gyyyb' } })
        },
        goInfo(num) {
            this.$router.push({ path: '/preprintIntro/p', query: { pN: encodeURI(this.pageName), id: 'yyb_gyyyb', title: num } })
        },
        goNews(id) {
            // this.$router.push({path: '/news/n', query: {pN: encodeURI(this.pageName), id: this.}})
            // this.$router.push({ path: '/news/n', query: { title: '最新资讯', id: 'sy_zxdt' } })
            this.$router.push({ path: '/news/n', query: { id: id, pN: encodeURI(this.pageName) } })
        },
        goNewsDetail(item) {
            if (item.jumpUrl) {
                window.open(item.jumpUrl, '_blank')
            } else if (item.originUrl) {
                window.open(item.originUrl, '_blank')
            } else if (item.mediaType == 'pdf' && item.mediaPath) {
                // pdf
                window.open(item.mediaPath, '_blank')
            }  else {
                this.$router.push({ path: '/nD/n', query: { id: item.id, pN: encodeURI(this.pageName) } })
            }
        },

        articleChange(val) {
            this.getArticleData(val)
        },
        partnerChange(val) {
            this.currentPage = val
            this.getCooBook(val)
        },
        getSubjectData() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/classify/xk',
            }).then((res) => {
                if (res.data.code == 0) {
                    _this.subjectData = res.data.data
                    _this.subjectData.unshift({ name: '全部', id: '' })
                }
            })
        },
        getBannerData() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/news/list?innerCode=yyb_lb',
                params: {
                    pageNum: 1,
                    pageSize: 4,
                },
            }).then((res) => {
                if (res.data.code == 0) {
                    _this.imgBannerData = res.data.data.rows
                }
            })
        },
        getNewsData() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/news/list?innerCode=yyb_zx',
                params: {
                    pageNum: 1,
                    pageSize: 8,
                },
            }).then((res) => {
                if (res.data.code == 0) {
                    _this.newsData = res.data.data.rows
                }
            })
        },
        getArticleDynamicData() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/news/list?innerCode=yyb_wzdt',
                params: {
                    pageNum: 1,
                    pageSize: 7,
                },
            }).then((res) => {
                if (res.data.code == 0) {
                    _this.articleDynamicData = res.data.data.rows
                }
            })
        },

        getArticleData(num) {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/aloneArticle/articleList',
                params: {
                    pageNum: num,
                    pageSize: 5,
                    classifyId: this.articleSubjectId,
                },
            }).then((res) => {
                if (res.data.code == 0) {
                    _this.articleData.articleList = res.data.data.rows
                    _this.articleData.total = res.data.data.total
                    // console.log('_this.articleData.articleList', _this.articleData.articleList)
                }
            })
        },
        getPlatformData() {
            var _this = this
            var size = 5
            if (this.isFirstPlatform == 2) {
                size = 5
            } else if (this.isFirstPlatform == 3) {
                size = 8
            }
            this.$http({
                method: 'get',
                url: '/admin/api/index/friendLinks',
                params: {
                    type: this.isFirstPlatform,
                    pageNum: 1, // 页码
                    pageSize: size, //每页条数
                },
            }).then((res) => {
                if (res.data.code == 0) {
                    if (this.isFirstPlatform == 2) {
                        this.inPlatformData = res.data.data.rows
                    } else if (this.isFirstPlatform == 3) {
                        this.outPlatformData = res.data.data.rows
                    }
                }
            })
        },
        getCooBook(num) {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/aloneArticle/partnerList',
                params: {
                    pageNum: num,
                    pageSize: 10,
                    classifyId: this.bookSubjectId,
                },
            }).then((res) => {
                if (res.data.code == 0) {
                    _this.bookData.bookList = res.data.data.rows
                    // _this.bookData.total = res.data.data.total
                    _this.total = res.data.data.total
                    _this.totalPageBook = Math.ceil(_this.total/10)
                    // console.log('bookData', _this.bookData.bookList)
                }
            })
        },
        getCooInstitution(num) {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/aloneArticle/archives',
                params: {
                    pageNum: num,
                    pageSize: 15,
                },
            }).then((res) => {
                if (res.data.code == 0) {
                    _this.cooInstitutionData = res.data.data.rows
                    _this.total = res.data.data.total
                    _this.totalPageIns = Math.ceil(_this.total/15)
                    // console.log('_this.cooInstitutionData', _this.cooInstitutionData)
                }
            })
        },
        getInfo() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/news/list?innerCode=yyb_jj',
            }).then((res) => {
                _this.bannerData.title = res.data.data.rows[0].title
                // console.log('res.data.data.rows[0]', res.data.data.rows[0])
                // _this.bannerData.content = res.data.data.rows[0].content
                // 去掉富文本中html标签和图片
                var msg = res.data.data.rows[0].content
                    .replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, '\n')
                    .replace(/<br\/?>/gi, '\n')
                    .replace(/<[^>/]+>/g, '')
                    .replace(/(\n)?<\/([^>]+)>/g, '')
                    .replace(/\u00a0/g, ' ')
                    .replace(/&nbsp;/g, ' ')
                    .replace(/<\/?(img)[^>]*>/gi, '')
                    .replace(/&amp;/g, '&')
                    .replace(/&lt;/g, '<')
                    .replace(/&gt;/g, '>')
                    .replace(/&#39;/g, "'")
                    .replace(/&quot;/g, '"')
                    .replace(/<\/?.+?>/g, '')
                _this.bannerData.content = msg
            })
        },
        getWordCloudChart() {
            let _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/aloneArticle/keyWord',
            }).then((res) => {
                if (res.data.code == 0) {
                    _this.wordCloudData = res.data.data
                }
            })
        },
        getInstro(id) {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/news/list',
                params: {
                    innerCode: 'yyb_gyyyb',
                    pageSize: 100,
                    pageNum: 1,
                },
            }).then((res) => {
                if (res.data.code == 0) {
                    _this.instroData = res.data.data.rows
                    _this.activeTitle = _this.instroData[0].title
                    _this.isUp = false
                    if (_this.instroData.length > 5) {
                        _this.isDown = true
                    } else {
                        _this.isDown = false
                    }
                }
            })
        },
    },
    created() {
        if (this.$route.query.pN) {
            this.pN = decodeURI(this.$route.query.pN)
        } else {
            this.pN = ''
        }
        this.getSubjectData()
        this.getPlatformData()
        this.getBannerData()
        this.getNewsData()

        this.getArticleDynamicData()
        this.getInfo()
        this.getArticleData(1)
        this.getWordCloudChart()

        this.getCooInstitution(1)
        this.getCooBook(1)

        this.getInstro()
    },
}
</script>

<style lang="scss" scoped>
.pagination-wrap {
    display: flex;
    align-items: center;
    .total-page {
        font-size: 14px;
        color: #333;
        margin-right: 4px;
    }
}
.title-preprint {
    width: 100%;
    height: 44px;
    background: #f5fbff;
    padding: 0 16px 0 12px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    h5 {
        color: #2b221b;
        font-size: 22px;
        padding-left: 14px;
        position: relative;
        line-height: 44px;
        font-weight: bold;
    }
    h5::after {
        content: '';
        width: 8px;
        height: 11px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: url(../assets/img/oa/icon-title-tip.png);
        background-size: 100% 100%;
    }
    a {
        font-size: 14px;
        line-height: 44px;
        color: #777777;
        cursor: pointer;
        img {
            width: 12px;
            height: 15px;
            margin-left: 3px;
            vertical-align: middle;
        }
    }
}

.title-preprint {
    h5 {
        display: flex;
        p {
            //width: 156px;
            font-size: 18px;
            color: #2b221b;
            font-weight: normal;
            cursor: pointer;
        }
        p.first-title-plat {
            position: relative;
            margin-right: 70px;
        }
        p.first-title-plat::after {
            content: '';
            position: absolute;
            top: 50%;
            right: -35px;
            transform: translateY(-50%);
            width: 1px;
            height: 16px;
            background: #d1d6d9;
        }
        p.sec-title-plat {
        }

        p.active-title-plat {
            font-size: 22px;
            font-weight: bold;
        }
    }
}
.preprint-page {
    .top-banner-wrap {
        width: 100%;
        //background: #cb2f3c;
        min-height: 300px;
        .top-banner-component {
            display: flex;
            justify-content: space-between;
            position: relative;
            z-index: 0;
            margin-top: 140px;
            margin-bottom: 50px;
            .left-top-banner {
                position: absolute;
                left: 74px;
                bottom: 0;
                background: rgba(255, 255, 255, 0.9);
                z-index: 2;
                width: 238px;
                height: 412px;
                padding: 22px 16px;
                box-sizing: border-box;
                z-index: 100;
                //border-top: 1px solid #C30D23;
                //border-image: linear-gradient(180deg, #C30D23, #C30D23) 10 10;
                border: 1px solid #fff;
                border-image: linear-gradient(#c30d23, #fff) 50 50;
                //border-image: -webkit-linear-gradient(red,#fff) 30 30;
                //border-image: -moz-linear-gradient(red,#fff) 30 30;
                h5 {
                    font-size: 22px;
                    line-height: 23px;
                    color: #2b221b;
                    font-weight: bold;
                    margin-bottom: 12px;
                    margin-bottom: 11px;
                }
                h6 {
                    font-size: 18px;
                    line-height: 16px;
                    color: #828282;
                    margin-bottom: 35px;
                }
                div {
                    z-index: 10;
                    .banner-content {
                        text-indent: 35px;
                        font-size: 14px;
                        line-height: 26px;
                        color: #2b221b;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 9;
                        -webkit-box-orient: vertical;
                    }
                    span {
                        font-size: 14px;
                        color: #2b221b;
                        float: right;
                        margin-top: 30px;
                        cursor: pointer;
                    }
                }
            }
            .right-top-banner {
                width: 1200px;
            }
        }
    }

    .preprint-main {
        .main-top {
            display: flex;
            justify-content: space-between;
            .latest-release {
                width: 880px;
                ul.title-btn {
                    display: flex;
                    margin-top: 20px;
                    li {
                        width: 176px;
                        height: 34px;
                        font-size: 14px;
                        color: #2b221b;
                        text-align: center;
                        line-height: 34px;
                        box-sizing: border-box;
                        border: 1px solid #eaeff3;
                        cursor: pointer;
                    }
                    li.active-title {
                        background: #c30d23;
                        color: #fff;
                        border: 1px solid #c30d23;
                    }
                }
                ul.article-list {
                    margin-top: 27px;
                    li {
                        margin-top: 18px;
                        padding-bottom: 14px;
                        border-bottom: 1px solid #eaeff3;
                        display: flex;

                        a {
                            margin-left: 2px;
                            margin-right: 11px;
                            margin-top: -4px;
                            img {
                                width: 28px;
                                //height: 21px;
                                cursor: pointer;
                            }
                        }
                        .article-content {
                            h6 {
                                color: #2b221b;
                                font-size: 16px;
                                line-height: 16px;
                                cursor: pointer;
                                span {
                                    display: inline-block;
                                    height: 20px;
                                    line-height: 20px;
                                    padding: 0 9px;
                                    background: #eaeff3;
                                    border-radius: 0px 10px 10px 10px;
                                    color: #828282;
                                    font-size: 12px;
                                }
                            }
                            div {
                                display: flex;
                                p {
                                    width: 280px;
                                    color: #828282;
                                    font-size: 14px;
                                    padding-right: 20px;
                                    line-height: 15px;
                                    margin-top: 14px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    box-sizing: border-box;
                                }
                            }
                        }
                    }
                }
            }
            .contribute-news-wrap {
                button {
                    border: none;
                    width: 299px;
                    height: 56px;
                    display: block;
                    background: url(../assets/img/oa/bg-contribution.png);
                    background-size: 100% 100%;
                    margin-bottom: 20px;
                    a {
                        display: block;
                        width: 299px;
                        height: 56px;
                        line-height: 56px;
                        font-size: 24px;
                        color: #fff;
                        font-weight: bold;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                }
            }
            .news-preprint {
                ul {
                    padding-top: 11px;
                    border: 1px solid #eaeff3;
                    padding-bottom: 16px;
                    li {
                        box-sizing: border-box;
                        padding: 0 19px 0 33px;
                        position: relative;
                        a {
                            width: 245px;
                            display: block;
                            padding-left: 10px;
                            font-size: 14px;
                            color: #2b221b;
                            line-height: 45px;
                            border-bottom: 1px solid #eaeff3;
                            box-sizing: border-box;
                            position: relative;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            cursor: pointer;
                        }
                        //a::before {
                        //    content: '';
                        //    width: 6px;
                        //    height: 6px;
                        //    background: #C30D23;
                        //    border-radius: 50%;
                        //    position: absolute;
                        //    left: -12px;
                        //    top: 50%;
                        //    transform: translateY(-50%);
                        //}
                    }
                    li::before {
                        content: '';
                        width: 6px;
                        height: 6px;
                        background: #c30d23;
                        border-radius: 50%;
                        position: absolute;
                        left: 21px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    li:last-child {
                        a {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
        .platform-dynamics {
            margin-top: 48px;
            display: flex;
            justify-content: space-between;
            .platform-left {
                width: 880px;
                .title-preprint {
                    h5 {
                        display: flex;
                        p {
                            font-size: 18px;
                            color: #2b221b;
                            font-weight: normal;
                            cursor: pointer;
                        }
                        p.first-title-plat {
                            position: relative;
                            margin-right: 70px;
                        }
                        p.first-title-plat::after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            right: -35px;
                            transform: translateY(-50%);
                            width: 1px;
                            height: 16px;
                            background: #d1d6d9;
                        }
                        p.sec-title-plat {
                        }

                        p.active-title-plat {
                            font-size: 22px;
                            font-weight: bold;
                        }
                    }
                }
                ul.abroad {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 40px;
                    padding-bottom: 38px;
                    li {
                        width: 196px;
                        margin-right: 13px;
                        margin-bottom: 20px;
                        a {
                            display: block;
                            position: relative;
                            z-index: 0;
                            cursor: pointer;
                            img {
                                display: block;
                                width: 196px;
                                height: 120px;
                            }
                            h6 {
                                line-height: 38px;
                                background: #f5f7f9;
                                font-size: 20px;
                                font-weight: bold;
                                color: #2b221b;
                                text-align: center;
                                width: 196px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                padding: 0 6px;
                                box-sizing: border-box;
                            }
                            p {
                                position: absolute;
                                width: 196px;
                                height: 158px;
                                left: 0;
                                top: 0;
                                background: rgba(0, 0, 0, 0.7);
                                z-index: 2;
                                padding: 24px 20px 0 20px;
                                cursor: pointer;
                                display: none;
                                span {
                                    display: block;
                                    font-size: 14px;
                                    color: #fff;
                                    line-height: 22px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 5; // 控制多行的行数
                                    line-clamp: 5;
                                    -webkit-box-orient: vertical;
                                    box-sizing: border-box;
                                }
                            }
                        }
                        a:hover {
                            p {
                                display: block;
                            }
                        }
                    }
                    li:nth-child(4n + 4) {
                        margin-right: 0;
                    }
                }
                ul.domestic {
                    margin-top: 10px;
                    li {
                        padding-bottom: 14px;
                        border-bottom: 1px solid #eaeff3;
                        display: flex;
                        justify-content: space-between;
                        a {
                            display: block;
                            width: 42px;
                            height: 42px;
                            line-height: 42px;
                            text-align: center;
                            background: #f5fbff;
                            border-radius: 2px;
                            font-size: 14px;
                            color: #2b221b;
                            margin-right: 8px;
                            margin-top: 17px;
                        }
                        .platform-content {
                            width: 830px;
                            h6 {
                                font-size: 16px;
                                color: #2b221b;
                                line-height: 16px;
                                margin-bottom: 10px;
                                margin-top: 16px;
                                cursor: pointer;
                            }
                            div {
                                p {
                                    color: #828282;
                                    font-size: 14px;
                                    line-height: 15px;
                                    width: 100%;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
            }

            .btn-wrap {
                //padding-top: 22px;
                //button {
                //    width: 300px;
                //    height: 30px;
                //    background: #fafbfc;
                //    border: none;
                //    border-radius: 4px;
                //    margin-bottom: 10px;
                //    cursor: pointer;
                //    display: flex;
                //    align-items: center;
                //    justify-content: center;
                //    cursor: not-allowed;
                //    img {
                //        width: 14px;
                //    }
                //}
                //ul {
                //    li {
                //        width: 300px;
                //        height: 56px;
                //        font-size: 18px;
                //        color: #0d1020;
                //        line-height: 56px;
                //        margin-bottom: 10px;
                //        text-align: center;
                //        background: url(../assets/img/oa///bg-link-default.png);
                //        background-size: 100% 100%;
                //        cursor: pointer;
                //    }
                //    li:hover {
                //        color: #fff;
                //        background: url(../assets/img/oa///bg-link-selected.png);
                //        background-size: 100% 100%;
                //    }
                //}

                button {
                    width: 300px;
                    height: 30px;
                    background: #fafbfc;
                    border: none;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 14px;
                    }
                }
                .down-btn {
                    margin-bottom: 0;
                    margin-top: 10px;
                }
                .button-wrap {
                    width: 300px;
                    max-height: 320px;
                    overflow: hidden;
                    position: relative;
                    .button-ul {
                        transition: 1s;
                    }
                }
                ul {
                    li {
                        width: 300px;
                        height: 56px;
                        font-size: 18px;
                        color: #0d1020;
                        line-height: 56px;
                        margin-bottom: 10px;
                        text-align: center;
                        background: url(../assets/img/oa/bg-link-default.png);
                        background-size: 100% 100%;
                        cursor: pointer;
                        a {
                            display: block;
                            width: 300px;
                            height: 56px;
                            font-size: 18px;
                            color: #0d1020;
                            line-height: 56px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            box-sizing: border-box;
                            padding: 0 10px;
                        }
                    }
                    li:hover {
                        color: #fff;
                        background: url(../assets/img/oa/bg-link-selected.png);
                        background-size: 100% 100%;
                        a {
                            color: #fff;
                        }
                    }
                }
            }



        }
        .bot-main {
            margin-top: 40px;
            display: flex;
            justify-content: space-between;
            .cooperation-left {
                width: 880px;
                ul.coo-institution {
                    margin-top: 12px;
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        margin-right: 11px;
                        margin-bottom: 22px;
                        a {
                            display: block;
                            img {
                                display: block;
                                width: 167px;
                                height: 146px;
                                border: 1px solid #eaeff3;
                                box-sizing: border-box;
                            }
                            p {
                                width: 167px;
                                height: 50px;
                                line-height: 18px;
                                display: flex;
                                justify-content: center;
                                flex-wrap: wrap;
                                align-items: center;
                                background: #f5f7f9;
                                text-align: center;
                                color: #2b221b;
                                font-size: 15px;
                                box-sizing: border-box;
                                padding: 0 10px;
                            }
                        }
                    }
                    li:nth-child(5n + 5) {
                        margin-right: 0;
                    }
                }
                div.coo-book {
                    padding-bottom: 38px;
                    //            ul.com-book {
                    //                margin-top: 25px;
                    //                display: flex;
                    //                flex-wrap: wrap;
                    //                li {
                    //                    margin-right: 15px;
                    //                    margin-bottom: 5px;
                    //                    img {
                    //                        width: 160px;
                    //                        height: 225px;
                    //                        margin-bottom: 55px;
                    //                        cursor: pointer;
                    //                        box-shadow: 0px 3px 9px 0px rgba(15, 11, 5, 0.29);
                    //                    }
                    //                    p {
                    //                        width: 160px;
                    //                        line-height: 16px;
                    //                        font-size: 16px;
                    //                        text-align: center;
                    //                        cursor: pointer;
                    //                        overflow: hidden;
                    //                        text-overflow: ellipsis;
                    //                        white-space: nowrap;
                    //                    }
                    //                }
                    //                li:nth-child(5n + 5) {
                    //                    margin-right: 0;
                    //                }
                    //            }
                    ul.com-book {
                        position: relative;
                        margin-top: 25px;
                        z-index: 0;
                        display: flex;
                        padding-left: 23px;
                        box-sizing: border-box;
                        li {
                            margin-right: 12px;
                            width: 157px;
                            padding-bottom: 5px;
                            img {
                                width: 156px;
                                height: 223px;
                                margin-bottom: 40px;
                                //55px;
                                cursor: pointer;
                                box-shadow: 0px 15px 18px -15px #4f4a4a;
                            }
                            p {
                                color: #0d1020;
                                font-size: 15px;
                                width: 156px;
                                line-height: 16px;
                                text-align: center;
                                cursor: pointer;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                        li:last-child {
                            margin-right: 0;
                        }
                    }
                    ul.com-book::after {
                        content: '';
                        position: absolute;
                        width: 960px; //977px;
                        height: 100px;
                        //116px;
                        left: 50%;
                        bottom: -10px;
                        transform: translateX(-50%);
                        z-index: -2;
                        background: url(../assets/img/oa/bookshelf2.png);
                        background-size: 100% 100%;
                    }
                    //ul.com-book:last-child {
                    //    margin-top: 0;
                    //}
                }

            }
            .more-right {
                width: 300px;
                .dynamics-right {
                    width: 299px;
                    ul {
                        padding-top: 9px;
                        border: 1px solid #eaeff3;
                        padding-bottom: 2px;
                        li {
                            box-sizing: border-box;
                            padding: 0 19px 0 33px;
                            position: relative;
                            a {
                                width: 245px;
                                display: block;
                                padding-left: 10px;
                                font-size: 14px;
                                color: #2b221b;
                                line-height: 45px;
                                border-bottom: 1px solid #eaeff3;
                                box-sizing: border-box;
                                position: relative;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                cursor: pointer;
                            }
                            //a::before {
                            //    content: '';
                            //    width: 6px;
                            //    height: 6px;
                            //    background: #C30D23;
                            //    border-radius: 50%;
                            //    position: absolute;
                            //    left: -12px;
                            //    top: 50%;
                            //    transform: translateY(-50%);
                            //}
                        }
                        li::before {
                            content: '';
                            width: 6px;
                            height: 6px;
                            background: #c30d23;
                            border-radius: 50%;
                            position: absolute;
                            left: 21px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        li:last-child {
                            a {
                                border-bottom: none;
                            }
                        }
                    }
                }
                .key-chart {
                    width: 300px;
                    height: 264px;
                    border: 1px solid #eaeff3;
                    margin-top: 22px;
                }

            }
        }
    }
}
</style>
